<template>
	<div>
		<Horizontal v-if="$store.getters.currentPageVersion === 'Horizontal'" :firstType="firstType"
			:secondType="secondType" @getGift="getGift" @copyCdk="copyCdk" @scrollList="scrollList" :secondTypeList="secondTypeList" :giftList="dataList"
			@selectFirstType="selectFirstType" @changeGiftType="changeGiftType" @getGiftDetail="getGiftDetail">
		</Horizontal>
		<Vertical v-else :firstType="firstType" :secondType="secondType" :secondTypeList="secondTypeList"
			:giftList="dataList" @getGift="getGift" @copyCdk="copyCdk" @scrollList="scrollList" @selectFirstType="selectFirstType" @changeGiftType="changeGiftType"
			@getGiftDetail="getGiftDetail"></Vertical>
	</div>
</template>

<script>
	import Horizontal from '@/components/Welfare/Index/Horizontal/Index.vue'
	import Vertical from '@/components/Welfare/Index/Vertical/Index.vue'


	export default {
		components: {
			Horizontal,
			Vertical
		},
		data() {
			return {
				currentPage: 1, //当前页
				limit: 10,
				firstType: 0,
				secondType: null,
				secondTypeList: [],
				dataList: []
			}
		},
		created() {
			this.getGiftTypeList()
		},
		methods: {
			//获取礼包或福利分类列表
			getGiftTypeList() {
				let requestMethods = null,
					requestData = {
						user_id: this.$store.state.userId,
						token: this.$store.state.token
					}
				switch (this.firstType) {
					case 0:
						requestMethods = 'getGiftTypeList'
						break
					default:
						break
				}
				eval(`this.$api.game.${requestMethods}(${JSON.stringify(requestData)})`).then(res => {
					if (res.data.code === 200) {
						this.secondTypeList = res.data.data
						if (this.secondTypeList.length > 0) {
							this.secondType = this.secondTypeList[0].gift_cat_id
							this.getList()
						}
					}
				})
			},
			//获取对应的礼包或福利列表
			getList() {
				let requestMethods = null,
					requestData = {
						user_id: this.$store.state.userId,
						token: this.$store.state.token,
						gift_cat_id: this.secondType,
						page:this.currentPage,
						limit:this.limit
					}
				switch (this.firstType) {
					case 0:
						requestMethods = 'getGiftList'
						break
					default:
						break
				}
				eval(`this.$api.game.${requestMethods}(${JSON.stringify(requestData)})`).then(res => {
					if (res.data.code === 200) {
						this.dataList = this.dataList.concat(res.data.data)
					}
				})
			},
			//滚动列表触发
			scrollList(e) {
			    this.$common.pagingRequest(e, this)
			},
			//切换礼包一级类型
			selectFirstType(type) {
				this.firstType = type
				this.currentPage = 1
				this.dataList = []
				this.getGiftTypeList()
			},
			//切换礼包二级类型
			changeGiftType(e) {
				switch (this.firstType) {
					case 0:
						this.secondType = e.gift_cat_id
						break
					default:
						break
				}
				this.currentPage = 1
				this.dataList = []
				this.getList()
			},
            //复制礼包码
            copyCdk(e) {
                let requestData = {
                		user_id: this.$store.state.userId,
                		token: this.$store.state.token,
                		gift_cat_id: this.secondType,
                		gift_id:e.gift_id
                	}
                this.$api.game.getGiftPack(requestData).then(res=> {
                	if (res.data.code === 200) {
                        this.$common.copyText(res.data.data.card_number, '礼包码')
                	}
                })
                
            },
			//领取礼包
			getGift(e) {
				let requestData = {
						user_id: this.$store.state.userId,
						token: this.$store.state.token,
						gift_cat_id: this.secondType,
						gift_id:e.gift_id
					},
					requestMethods = null
					switch (this.firstType) {
						case 0:
							requestMethods = 'getGiftPack'
							break
						default:
							break
					}
				eval(`this.$api.game.${requestMethods}(${JSON.stringify(requestData)})`).then(res=> {
					if (res.data.code === 200) {
						this.$Toast({
							message: '领取成功！',
						})
						setTimeout(()=> {
							this.currentPage = 1
							this.dataList = []
							this.getList()
						}, 2000)
						
					}
				})
			},
			//获取礼包详情
			getGiftDetail(item) {
				if (this.firstType === 0) {
					this.$router.push({
						name: 'GiftDetail',
						params: {
							item: item
						}
					})
				} else {
					this.$router.push({
						name: 'WelfareDetail',
						params: {
							item: item
						}
					})
				}

			}
		}
	}
</script>

<style>
	
</style>